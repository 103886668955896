// Function to handle WebRTC connection
var connection = new RTCMultiConnection();
// var designer = new CanvasDesigner();
var canvas
var ctx
var manyTomany = false
var designer = new CanvasDesigner();
var chunk_size = 60 * 1000;
var w_stream;
function oneToMany(roomId, functionName) {
  let count = 0;
  // Setting the socket server URL for connection

  connection.socketURL = "https://awrxhub.com:9550/";
  const role = localStorage.getItem('UserRole').toLocaleLowerCase()
  const info = JSON.parse(localStorage.getItem('userInfo'))
  connection.socketMessageEvent = "video-conference";
  connection.extra = {
    role: localStorage.getItem('UserRole'),
    userName: info.FullName,
    UserId: info.UserId,
    info: localStorage.getItem('userInfo')
  };
  connection.session = {
    audio: role === 'teacher',
    video: true,
    data: true
  };
  connection.channel = roomId;
  // if (document.getElementById("screenShare")) {
  //   var btnScreenShare = document.getElementById("screenShare");
  //   btnScreenShare.onclick = function () {
  //   }
  // }
  if (document.getElementById("stopscreenShare")) {
    var btnStopscreenShare = document.getElementById("stopscreenShare");
    btnStopscreenShare.onclick = function () {
      connection.session = {
        audio: role === 'teacher',
        video: true,
        data: true
      };
    }
  }
  // Constraints for SDP (Session Description Protocol)
  connection.sdpConstraints.mandatory = {
    OfferToReceiveAudio: true,
    OfferToReceiveVideo: true,
  };
  // Detecting available input devices (microphone, webcam, speakers)
  connection.DetectRTC.load(function () {
    if (connection.DetectRTC.hasMicrophone === true) {
      connection.mediaConstraints.audio = true;
      connection.session.audio = true;
    } else {
      alert("Please attach a microphone device.");
      connection.mediaConstraints.audio = false;
      connection.session.audio = false;
    }

    if (connection.DetectRTC.hasWebcam === true) {
      connection.mediaConstraints.video = true;
      connection.session.video = true;
    } else {
      alert("Please attach a camera device.");
      connection.mediaConstraints.video = false;
      connection.session.video = false;
    }
    if (connection.DetectRTC.hasSpeakers === false) {
      // checking for "false"
      alert(
        "Please attach a speaker device. You will unable to hear the incoming audios."
      );
    }
  });
  connection.fileReceived = {};
  connection.chunkSize = chunk_size;
  connection.enableFileSharing = true;
  var bitrates = 512;
  var resolutions = "Ultra-HD";
  var videoConstraints = {};
  if (resolutions == "HD") {
    videoConstraints = {
      width: {
        ideal: 1280,
      },
      height: {
        ideal: 720,
      },
      frameRate: 30,
    };
  }
  if (resolutions == "Ultra-HD") {
    videoConstraints = {
      width: {
        ideal: 1920,
      },
      height: {
        ideal: 1080,
      },
      frameRate: 30,
    };
  }
  connection.mediaConstraints = {
    video: videoConstraints,
    audio: {
      echoCancellation: true,
      noiseSuppression: true,
    },
  };
  // Handling SDP to set video codecs and bitrates

  var CodecsHandler = connection.CodecsHandler;

  connection.processSdp = function (sdp) {
    var codecs = "vp8";

    if (codecs.length) {
      sdp = CodecsHandler.preferCodec(sdp, codecs.toLowerCase());
    }

    if (resolutions == "HD") {
      sdp = CodecsHandler.setApplicationSpecificBandwidth(sdp, {
        audio: 128,
        video: bitrates,
        screen: bitrates,
      });

      sdp = CodecsHandler.setVideoBitrates(sdp, {
        min: bitrates * 8 * 1024,
        max: bitrates * 8 * 1024,
      });
    }

    if (resolutions == "Ultra-HD") {
      sdp = CodecsHandler.setApplicationSpecificBandwidth(sdp, {
        audio: 128,
        video: bitrates,
        screen: bitrates,
      });

      sdp = CodecsHandler.setVideoBitrates(sdp, {
        min: bitrates * 8 * 1024,
        max: bitrates * 8 * 1024,
      });
    }

    return sdp;
  };

  // Setting ICE servers for STUN/TURN

  connection.iceServers = [
    {
      urls: [
        "stun:onmedrx.org:5349",
        "stun:stun1.l.google.com:19302",
        "stun:stun2.l.google.com:19302",
        "stun:stun.l.onmedmedia.com:5349",
        "stun:stun1.l.onmedmedia.com:5349",
      ],
    },
  ];

  connection.iceServers.push({
    urls: "turn:onmedrx.org:5349",
    credential: "test123",
    username: "test",
  });
  // Setting the containers for local and remote videos

  connection.localVideosContainer = document.getElementById("local");
  connection.remoteVideosContainer = document.getElementById("remote");

  document.getElementById('screenShare').onclick = async function () {
    // Get screen constraints
    let mediaStream = null;
    try {

      connection.addStream({
        screen: true,
        audio: true,
      });
      const userInfo = {
        role: localStorage.getItem('UserRole'),
        userName: info.FullName,
        screenShare: true,
        UserId: info.UserId,
        info: localStorage.getItem('userInfo')
      };
      connection.extra = userInfo
      connection.getAllParticipants().forEach(function (pid) {
        connection.addTrack(connection.attachStreams[0], pid);
        startScreenSharing(connection.attachStreams[0])
        // connection.sendCustomMessage(pid, { type: 'update-user-info', extra: userInfo });
      });
    } catch (ex) {
      console.log("Error occurred", ex);
    }
  };
  // Handling the onstream event to display video streams

  connection.onstream = function (event) {
    console.log(event.stream);
    
    var existing = document.getElementById(event.streamid);
    var userExtraRole = event.extra.role.toLocaleLowerCase();
    var userExtraName = event.extra.userName.toLocaleLowerCase();
    var isScreen = event.extra.screenShare ?? false
    var isWhiteboard = event.extra.whiteboardShare ?? false
    if (existing && existing.parentNode) {
      existing.parentNode.removeChild(existing);
    }
    event.mediaElement.muted = true;
    event.mediaElement.volume = 0;
    var video = document.createElement("video");
    try {
      video.setAttributeNode(document.createAttribute("autoplay"));
      video.setAttributeNode(document.createAttribute("playsinline"));
      video.setAttributeNode(document.createAttribute("id", "videofeed"));
    } catch (e) {
      video.setAttribute("autoplay", true);
      video.setAttribute("playsinline", true);
    }
    video.srcObject = event.stream;
    let displayButtons = document.getElementById("displayButtons");
    displayButtons.style.display = "flex";
    if (isScreen) {
      $("#showconsultationsidebar").addClass("Shared-screen");
      $(".teacher").addClass("teacher-screen-share");
      $(".Shared-screen").removeClass("student_max04");

    }//else 
    if (isWhiteboard) {
      const element = document.getElementById('showconsultationsidebar');
      element.classList.remove("student_max04");
      element.classList.add("Shared-screen");
      $(".teacher").addClass("teacher-whitboard-share");
    }//else 
    if (event.type === "local") {
      video.volume = false;
      video.id = event.userid;
      try {
        video.setAttributeNode(document.createAttribute("muted"));
      } catch (e) {
        video.setAttribute("muted", true);
      }
      var width = 500;
      var mediaElement = getHTMLMediaElement(video, {
        title: userExtraName,
        buttons: ["full-screen"],
        width: width,
        showOnMouseEnter: true,
      });
      // mediaElement.addClass(userExtraInfo.role.toLowerCase() === "student"? "student":"teacher")
      if (isScreen) {
        mediaElement.classList.add("screen-feed")
      }
      connection.localVideosContainer.appendChild(mediaElement);
      var localVideosContainer = document.getElementById("local");
      localVideosContainer.appendChild(mediaElement);
      if (document.getElementById("localloaderbutton")) {
        $("#localloaderbutton").hide();
      }
      if (document.getElementById("btnLeave")) {
        var btnLeave = document.getElementById("btnLeave");
        btnLeave.onclick = function () {
          connection.getAllParticipants().forEach(function (pid) {
            connection.disconnectWith(pid);
          });
          connection.attachStreams.forEach(function (localStream) {
            localStream.stop();
          });
          connection.closeSocket();
        };
      }
      if (document.getElementById("switch-call")) {
        var btnLeave = document.getElementById("switch-call");
        btnLeave.onclick = function () {
          connection.getAllParticipants().forEach(function (pid) {
            connection.disconnectWith(pid);
          });
          connection.attachStreams.forEach(function (localStream) {
            localStream.stop();
          });
          connection.closeSocket();
        };
      }
      if (document.getElementById("switch-call-2")) {
        var btnLeave = document.getElementById("switch-call-2");
        btnLeave.onclick = function () {
          connection.getAllParticipants().forEach(function (pid) {
            connection.disconnectWith(pid);
          });
          connection.attachStreams.forEach(function (localStream) {
            localStream.stop();
          });
          connection.closeSocket();
        };
      }
      if (document.getElementById("fullWidth")) {
        const fullWidth = document.getElementById("fullWidth");
        fullWidth.onclick = function () {
          $("#showconsultationsidebar").addClass("fullVideoWidth");
          $("#fullWidth").hide();
          $("#bigscreen").hide();
          $("#closefullWidth").show();
        };
      }
      if (document.getElementById("closefullWidth")) {
        const closefullWidth = document.getElementById("closefullWidth");
        closefullWidth.onclick = function () {
          $("#showconsultationsidebar").removeClass("fullVideoWidth");
          $("#closefullWidth").hide();
          $("#fullWidth").show();
          $("#bigscreen").show();
        };
      }
      if (document.getElementById("bigscreen")) {
        const bigscreen = document.getElementById("bigscreen");
        bigscreen.onclick = function () {
          $("#showconsultationsidebar").addClass("small_screen_local");
          $("#sectiondata").addClass("small_screen");
          $("#sectiondata").removeClass("big_screen");
          $("#showconsultationsidebar").removeClass("big_screen_local");
          $("#bigscreen").hide();
          $("#fullWidth").hide();
          $("#smallscreen").show();
        };
        const smallscreen = document.getElementById("smallscreen");
        smallscreen.onclick = function () {
          $("#showconsultationsidebar").addClass("big_screen_local");
          $("#sectiondata").addClass("big_screen");
          $("#sectiondata").removeClass("small_screen");
          $("#showconsultationsidebar").removeClass("small_screen_local");
          $("#smallscreen").hide();
          $("#bigscreen").show();
          $("#fullWidth").show();
        };
      }
      var btnMute = document.getElementById("btnMute");
      btnMute.onclick = function () {
        $("#btnMute").hide();
        $("#btnUnMute").show();
        connection.attachStreams.forEach(function (localStream) {
          localStream.mute("audio");
        });
      };
      var btnUnMute = document.getElementById("btnUnMute");
      btnUnMute.onclick = function () {
        $("#btnMute").show();
        $("#btnUnMute").hide();
        connection.attachStreams.forEach(function (localStream) {
          localStream.unmute("audio");
        });
        connection.mediaConstraints = {
          audio: {
            echoCancellation: true,
            noiseSuppression: true,
          },
        };
      };
      var btnCam = document.getElementById("off-cam");
      btnCam.onclick = function () {
        $("#off-cam").hide();
        $("#start-cam").show();
        connection.attachStreams.forEach(function (localStream) {
          localStream.mute("video");
        });
      };
      var btnStartCam = document.getElementById("start-cam");
      btnStartCam.onclick = function () {
        $("#off-cam").show();
        $("#start-cam").hide();

        connection.attachStreams.forEach(function (localStream) {
          localStream.unmute("video");
        });
      };
    } else if (event.type === "remote") {
      console.log(event.extra);
      
      video.id = event.userid;
      connection.onleave = function (event) {
        count--;
        if (count < 2) {
          // $("#showconsultationsidebar").removeClass("student_max04");
          $("#showconsultationsidebar").removeClass("student_max04");
          // $("#patientVideo").addClass("multipleFeed");
        } else if (count < 5) {
          $("#showconsultationsidebar").removeClass("student_max05");
          // $("#patientVideo").addClass("multipleFeed");
        }
      };

      if (document.getElementById("btnLeave")) {
        var btnLeave = document.getElementById("btnLeave");
        btnLeave.onclick = function () {
          connection.getAllParticipants().forEach(function (pid) {
            connection.disconnectWith(pid);
          });
        };
      }
      if (connection.getAllParticipants().length > 3) {
        $("#showconsultationsidebar").removeClass("singleVideo")
        $("#showconsultationsidebar").removeClass("twovideo")
      }
      connection.getAllParticipants().forEach(function (participantId) {
        var user = connection.peers[participantId];
        var hisIncomingStreams = user.peer.getReceivers();
        if (document.getElementById("remoteloaderbutton")) {
          $("#remoteloaderbutton").hide();
        }
      });
      if ((role === 'student' && userExtraRole === 'teacher') || role === 'teacher' || manyTomany) {


        var width = 500;
        var mediaElement = getHTMLMediaElement(video, {
          title: userExtraName,
          buttons: ["full-screen"],
          width: width,
          showOnMouseEnter: true,
        });
        if (isScreen) {
          mediaElement.classList.add("screen-feed")
        }
        // mediaElement.addClass(userExtraInfo.role.toLowerCase() === "teacher"? "teacher":'')
        connection.remoteVideosContainer.appendChild(mediaElement);

        var remoteVideosContainer = document.getElementById("remote");
        remoteVideosContainer.appendChild(mediaElement);
        if(!isScreen && !isWhiteboard) count++;
        if (count > 4) {
          
          $("#showconsultationsidebar").addClass("student_max04");
          $("#showconsultationsidebar").addClass("student_max05");
          // $("#patientVideo").addClass("multipleFeed");
        } else if (count >= 2) {
          $("#showconsultationsidebar").addClass("student_max04");
          // $("#patientVideo").addClass("multipleFeed");
        }
      }
    }

    setTimeout(function () {
      mediaElement.media.play();
    }, 5000);

    mediaElement.id = event.streamid;

    // to keep room-id in cache
    localStorage.setItem(connection.socketMessageEvent, connection.sessionid);

    if (event.type === "local") {
      connection.socket.on("disconnect", function () {
        if (!connection.getAllParticipants().length) {
        }
      });
    }
  };

  // Handling the onstreamended event

  connection.onstreamended = function (event) {
    console.log("here");
    
    var mediaElement = document.getElementById(event.streamid);
    var userExtraRole = event.extra?.role?.toLocaleLowerCase();
    var userExtraRUserId = event.extra?.UserId;
    var curUserId = localStorage.getItem("UserId");
    
    const hasClass = mediaElement?.parentNode?.classList?.contains("teacher-screen-share")
    if (hasClass) {
      $("#showconsultationsidebar").removeClass("Shared-screen");
      $(".teacher").removeClass("teacher-screen-share");

    } else {
      const eventData = JSON.parse(localStorage.getItem("eventData"))
      const actionData = {
        "EventId": eventData.EventId,
        "Action_Type": "Leave"
      }
      let apiurl = localStorage.getItem("apiUrl");
      let schoolauth = localStorage.getItem("schoolAuth");
      if (mediaElement) {
        mediaElement.parentNode.removeChild(mediaElement);
      }
        fetch(apiurl + '/saveRoomStatusInfo', {
        method: 'POST',
        headers: {
          schoolauth,
          'Content-Type': 'application/json',  // Set the content type to JSON
        },
        body: JSON.stringify(actionData)
      })
        .then((response) => {
          if (response.ok) {
            // window.close()
            var groupId = localStorage.getItem("groupId")
            if (((groupId != null || groupId != '') && userExtraRole == 'teacher')|| (userExtraRole == 'student' && userExtraRUserId == curUserId)) {
              window.location.href = "/call-feedback"
            }
            // window.history.pushState({}, null, "/call-feedback");
          } else {
            localStorage.setItem("recordingerror", true);
            console.error("Failed to upload recording:", response.statusText);
          }
        })
        .catch((error) => {
          console.error("Error uploading recording:", error);
        });
    }

  };

  // Handling media errors

  connection.onMediaError = function (e) {
    if (e.message === "Concurrent mic process limit.") {
      if (DetectRTC.audioInputDevices.length <= 1) {
        alert("Please select external microphone.");
        return;
      }

      var secondaryMic = DetectRTC.audioInputDevices[1].deviceId;
      connection.mediaConstraints.audio = {
        deviceId: secondaryMic,
      };

      connection.join(connection.sessionid);
    }
  };

  function disableInputButtons(enable) { }

  connection.onFileStart = function (file) {
    // console.log('File transfer started:', file.name);
  };

  // Event for file transfer progress
  connection.onFileProgress = function (chunk) {
    const progress = Math.round((chunk.currentPosition / chunk.maxChunks) * 100);
    // console.log('File transfer progress:', progress + '%');
  };

  // Event when file transfer completes
  connection.onFileEnd = function (file) {
    let user;
    connection.getAllParticipants().forEach(function (participantId) {
      if (participantId == file.userid) {
        user = connection.peers[participantId];
      }
    });
    var userName = user ? user.extra.userName : connection.extra.userName
    // console.log(connection.extra);

    var classList = connection.userid == file.userid ? "incoming" : "outgoing"
    // // Create a download link for the received file
    // const downloadAnchor = document.createElement('a');
    // downloadAnchor.href = file.url;
    // downloadAnchor.download = file.name;
    // downloadAnchor.textContent = 'Download the file';
    // document.body.appendChild(downloadAnchor);
    receivedFiles(file, userName, classList)
  };

  (function () {
    var params = {},
      r = /([^&=]+)=?([^&]*)/g;

    function d(s) {
      return decodeURIComponent(s.replace(/\+/g, " "));
    }
    var match,
      search = window.location.search;
    while ((match = r.exec(search.substring(1))))
      params[d(match[1])] = d(match[2]);
    window.params = params;
  })();

  var roomid = "";
  if (localStorage.getItem(connection.socketMessageEvent)) {
    roomid = localStorage.getItem(connection.socketMessageEvent);
  } else {
    roomid = connection.token();
  }

  var hashString = location.hash.replace("#", "");
  if (hashString.length && hashString.indexOf("comment-") == 0) {
    hashString = "";
  }

  var roomid = params.roomid;
  if (!roomid && hashString.length) {
    roomid = hashString;
  }

  if (roomId && roomId.length) {
    localStorage.setItem(connection.socketMessageEvent, roomId);

    connection.checkPresence(roomId, function (isRoomExist, roomId) {
      if (functionName == "open") {
        if (isRoomExist === true) {
          arrStr = [];
        } else {
          connection.open(roomId);
        }
      }

      if (functionName == "join") {
        if (isRoomExist === true) {
          connection.join(roomId);
        } else {
          setTimeout(function () {
            webrtc(roomId, "join");
          }, 5000);
        }
      }

      if (functionName == "openOrJoin") {
        connection.openOrJoin(roomId);
      }
    });

    disableInputButtons();
  }
  connection.onmessage = function (event) {
    const dataObj = event.data

    const senderRole = event.extra.role
    const senderUser = event.extra.userName
    // console.log(dataObj,typeof dataObj ,senderRole);

    if (typeof dataObj == 'string') {
      try {
        var data = JSON.parse(dataObj)
        if (data.type == "message") {
          openMessageBox(data, role)
          appendElements(data.text, senderUser, "incoming")
        } else if (data.type == "handraise") {
          handRaise(event)
        } else if (data.type == "hand-down") {
          handDown(event)
        } else if (data.type == "handraise-accepted") {
          // Get the button element by its ID
          openMessageBox(data, role)
        } else if (data.type == "handraise-deny") {
          // Get the button element by its ID
          handRaiseDeny(data, role)
        } else if (data.type == "creatingGroup") {
          creatingGroup(event)
        } else if (data.type == "splitGroupList") {
          splitGroupList(event)
        } else if (data.type == "drop-message-permission") {
          dropMessagePermission(data)
        } else if (data.type == "close-whiteboard") {
          closeWhiteboard(data)
        } else if (data.type == "rec-on") {
          recOn()
        } else if (data.type == "rec-off") {
          recOff(event)
        } else if (data.type == "type-change" && senderRole.toLowerCase() == "teacher") {
          manyTomany = !manyTomany
          if (manyTomany) {
            allowAllStream("all")
          } else {
            removeAllStream("all")
          }
        }
        // else if (data.type == "file-share") {
        //   console.log("kkkkkkkkk");

        //   receivedFiles(data)
        // }
      } catch (error) {
        console.error('Error parsing JSON:', error);
      }
    } else if (dataObj instanceof ArrayBuffer) {
      // Handle binary data (ArrayBuffer)
      // console.log('Received binary data:', dataObj  );
    }
  };
  if (
    navigator.connection &&
    navigator.connection.type === "cellular" &&
    navigator.connection.downlinkMax <= 0.115
  ) {
    alert("2G is not supported. Please use a better internet service.");
  }
}
function removeAllStream(userid) {
  connection.streamEvents.selectAll().forEach(function (event) {
    var userExtraRole = event.extra.role.toLocaleLowerCase();
    // console.log(event);
    var count = 0;
    const streamEvent = document.getElementById(event.streamid);
    if (event.type === "remote" && streamEvent && userExtraRole == "student") {
      streamEvent.remove()
      count--;
      if (count < 2) {
        $("#showconsultationsidebar").removeClass("student_max04");
      } else if (count < 5) {
        $("#showconsultationsidebar").removeClass("student_max05");
      }
    }
  })
}
function allowAllStream(userid) {

  // console.log(connection.streamEvents.selectAll());
  // console.log(typeof connection.streamEvents);
  // const keys = Object.keys(connection.streamEvents);

  // console.log(userid);
  var count = 1;
  connection.streamEvents.selectAll().forEach(function (event) {

    // console.log(event);
    const streamEvent = document.getElementById(event.streamid);
    if (event.type === "remote" && !streamEvent && (userid == "all" || event.userid == userid)) {
      // console.log("here",manyTomany);
      // console.log(count);


      var userExtraRole = event.extra.role.toLocaleLowerCase();
      var userExtraName = event.extra.userName.toLocaleLowerCase();
      const role = localStorage.getItem('UserRole').toLocaleLowerCase()
      var video = document.createElement("video");
      video.id = event.userid;
      if (document.getElementById("btnLeave")) {
        var btnLeave = document.getElementById("btnLeave");
        btnLeave.onclick = function () {
          connection.getAllParticipants().forEach(function (pid) {
            connection.disconnectWith(pid);
          });
        };
      }
      if (connection.getAllParticipants().length > 3) {
        $("#showconsultationsidebar").removeClass("singleVideo")
        $("#showconsultationsidebar").removeClass("twovideo")
      }
      if ((role === 'student' && userExtraRole === 'teacher') || role === 'teacher' || (manyTomany || event.userid == userid)) {
        var width = 500;
        var mediaElement = getHTMLMediaElement(video, {
          title: userExtraName,
          buttons: ["full-screen"],
          width: width,
          showOnMouseEnter: true,
        });
        if (userExtraRole === 'student') {
          mediaElement.classList.add("student-casting")
        }
        mediaElement.id = event.streamid
        // console.log("here",mediaElement);
        // if (isScreen) {
        //   mediaElement.classList.add("screen-feed")
        // }
        // mediaElement.addClass(userExtraInfo.role.toLowerCase() === "teacher"? "teacher":'')
        connection.remoteVideosContainer.appendChild(mediaElement);

        var remoteVideosContainer = document.getElementById("remote");
        remoteVideosContainer.appendChild(mediaElement);
        count++;
        if (count > 4) {
          $("#showconsultationsidebar").addClass("student_max04");
          $("#showconsultationsidebar").addClass("student_max05");
          // $("#patientVideo").addClass("multipleFeed");
        } else if (count >= 2) {
          $("#showconsultationsidebar").addClass("student_max04");
          // $("#patientVideo").addClass("multipleFeed");
        }
      }
    }
  });
}
function recOn() {
  const showconsultationsidebar = document.getElementById("showconsultationsidebar");
  showconsultationsidebar.classList.add('rec-on');
}
function recOff() {
  const showconsultationsidebar = document.getElementById("showconsultationsidebar");
  showconsultationsidebar.classList.remove('rec-on');
}
function cameraOff() {
  const video = document.querySelector("video");

  // A video's MediaStream object is available through its srcObject attribute
  if (video) {
    const mediaStream = video.srcObject;

    // Through the MediaStream, you can get the MediaStreamTracks with getTracks():
    const tracks = mediaStream.getTracks();

    // Tracks are returned as an array, so if you know you only have one, you can stop it with:
    tracks[0].stop();

    // Or stop all like so:
    tracks.forEach((track) => track.stop());
  }
}

function shreFiles() {
  // var docFile;
  // console.log(docFile);
  // console.log('File transfer completed:', docFile.name);
  // console.log('File transfer url:', URL.createObjectURL(docFile));
  // var msg={
  //   type:"file-share",
  //   url:URL.createObjectURL(docFile),
  //   name:docFile.name
  // }
  // console.log(msg,connection);

  // sendMessage(JSON.stringify(msg))
  // connection.shareFile(docFile,connection.userid)
  var fileSelector = new FileSelector();
  fileSelector.selectSingleFile(function (file) {
    connection.send(file)
  });
}

function getActiveParticipants() {
  var list = []
  connection.getAllParticipants().forEach(function (participantId) {
    let user = connection.peers[participantId];
    list.push(user.extra.UserId)
  });
  return list
}

function sendMessage(text) {
  console.log(text);

  connection.send(text); // Send the text message
  var data = JSON.parse(text)
  const info = JSON.parse(localStorage.getItem('userInfo'))
  const userName = info.FullName
  if (data.type == "message")
    appendElements(data.text, userName, "outgoing")
}
function closeWhiteboard(data) {
  const hasClass = document.getElementsByClassName("teacher-whitboard-share")
  if (hasClass) {
    $(`#${data.streamid}`).remove();
    $("#showconsultationsidebar").removeClass("Shared-screen");
    $(".teacher").removeClass("teacher-whitboard-share");

  }
}
function handDown(eventdt) {
  const parentDiv = document.getElementById(`outer-${eventdt.userid}`);
  parentDiv.remove()
}
function handRaise(eventdt) {
  const videoElement = document.getElementById(eventdt.userid);
  // Check if the video element exists and then get the parent 'media-container' div
  if (videoElement) {
    const mediaContainer = videoElement.closest('.media-box');
    const hasClass = mediaContainer.classList.contains('hand-raise')
    if (!hasClass) {
      mediaContainer.classList.add('hand-raise');
      // Button Parent Div Add
      const parentDiv = document.createElement('div');
      parentDiv.classList.add('hand-raise-outer');
      parentDiv.id = `outer-${eventdt.userid}`;
      const button = document.createElement('button');
      const handRiseAccept = document.getElementById('acceptBtn');

      // Set the button's attributes
      button.classList.add('action-links');
      button.title = "accept";
      button.id = `accept-${eventdt.userid}`; // Unique ID for each button

      // Set the inner HTML to include an icon
      button.innerHTML = `<span class="fa fa-hand-paper"></span>`;

      // Add an event listener and pass the specific data to the handRaise function
      button.addEventListener('click', function () {
        acceptRequest(eventdt.userid, mediaContainer.id, eventdt); // Pass the data object to the handRaise function
      });
      parentDiv.appendChild(button);
      // Append the button to a parent element in the DOM
      const button2 = document.createElement('button');
      const handRiseDeny = document.getElementById('denyBtn');

      // Set the button2's attributes
      button2.classList.add('action-links');
      button2.classList.add('action-deny');
      button2.title = "deny";
      button2.id = `deny-${eventdt.userid}`; // Unique ID for each button2

      // Set the inner HTML to include an icon
      button2.innerHTML = `<span class="fa fa-times"></span>`;

      // Add an event listener and pass the specific data to the handRaise function
      button2.addEventListener('click', function () {
        denyRequest(eventdt.userid, mediaContainer.id, eventdt); // Pass the data object to the handRaise function
      });

      parentDiv.appendChild(button2);
      mediaContainer.appendChild(parentDiv);
    }
  }
}
function acceptRequest(userid, mediaContainer_id, eventdt) {
  sendMessage(JSON.stringify({ type: "handraise-accepted", receiver: userid }))
  removeHandraises(userid, "accept", eventdt)
}
function denyRequest(userid, mediaContainer_id, eventdt) {
  sendMessage(JSON.stringify({ type: "handraise-deny", receiver: userid }))
  removeHandraises(userid, "deny", eventdt)
}

function removeHandraises(userid, type, eventdt) {
  const acceptButton = document.getElementById(`accept-${userid}`);
  const parentDiv = document.getElementById(`outer-${userid}`);
  if (type == "accept") {
    const button = document.createElement('button');
    const handRiseAccept = document.getElementById('acceptBtn');

    // Set the button's attributes
    button.classList.add('action-links');
    button.title = "disable-messaging";
    button.id = `disable-message-${eventdt.userid}`; // Unique ID for each button

    // Set the inner HTML to include an icon
    button.innerHTML = `<span class="fa fa-hand-paper"></span>`;

    // Add an event listener and pass the specific data to the handRaise function
    button.addEventListener('click', function () {
      document.getElementById(`outer-${userid}`).remove()
      sendMessage(JSON.stringify({ type: "drop-message-permission", receiver: userid })) // Pass the data object to the handRaise function
    });
    parentDiv.appendChild(button);
  }
  if (acceptButton) {
    const videoElement = document.getElementById(userid);
    const mediaContainer = videoElement.closest('.media-box');
    mediaContainer.classList.remove('hand-raise')
    acceptButton.remove();
  }
  const denyButton = document.getElementById(`deny-${userid}`);
  if (denyButton) {
    denyButton.remove();
  }
}
function receivedFiles(data, userName, classList) {
  const parent = document.getElementById('displayButtons');
  const child = parent.querySelector('.mute-video-btn-outer'); // Gets the first child with class "child"
  const button = document.createElement('a');

  // Set attributes
  button.id = 'download';
  button.href = data.url;
  button.className = 'control-btn';
  button.download = data.name;
  button.setAttribute('data-bs-toggle', 'tooltip');
  button.setAttribute('data-bs-placement', 'top');
  button.setAttribute('title', 'Download Recording');

  // Create and add child <span> element
  const span = document.createElement('span');
  span.className = 'bi bi-arrow-down-circle';
  button.appendChild(span);

  // Append the button to the desired parent element (e.g., <body> or a specific container)
  // child.appendChild(button);
  appendElements(button, userName, classList)
}

function receivecMessage() {

}

function creatingGroup(event) {
  $("#open-breakout-popup").click()
}
function splitGroupList(event) {
  var data = JSON.parse(event.data)
  $("#breakout-loader-1").hide()
  $("#breakout-switch-1").show()
  $("#switch-call").show()
  var curGroup = data.list.find(itm => itm.UserId == connection.extra.UserId)
  // console.log(curGroup);

  if (curGroup) {
    localStorage.setItem("groupId", curGroup.groupId)
    localStorage.setItem("grouproomId", curGroup.roomId)
  } else {
    document.getElementById("close-popup").click();
  }
}

function openMessageBox(data, userRole) {
  const showchatBtn = document.getElementById('showchat');
  // const hidechatBtn = document.getElementById('hidechat');
  showchatBtn.style.display = "flex"
  allowAllStream(data.receiver)
  if ((connection.userid == data.receiver && userRole == 'student') || userRole == 'teacher') {
    document.getElementById('input-container').style.display = "flex";
    const handraiseBtn = document.getElementById('hand-raise-btn')
    if (handraiseBtn) {
      handraiseBtn.classList.add("active");
      handraiseBtn.disabled = true;
    }
  } else {
    document.getElementById('input-container').style.display = "none";
  }
  // Check if the button exists before trying to click it
  if (showchatBtn) {
    showchatBtn.click();
  }
}
function dropMessagePermission(data) {
  removeAllStream("all")
  if (connection.userid == data.receiver) {
    document.getElementById('input-container').style.display = "none";
    const handraiseBtn = document.getElementById('hand-raise-btn')
    {
      handraiseBtn.classList.remove("active");
      handraiseBtn.disabled = false;
    }
    const handraiseBtnClose = document.getElementById('handRaised-close')
    if (handraiseBtnClose) {
      handraiseBtnClose.click()
    }
  }
}
function handRaiseDeny(data, userRole) {
  if (connection.userid == data.receiver) {
    const handraiseBtn = document.getElementById('hand-raise-btn')
    const handraiseBtnClose = document.getElementById('handRaised-close')
    if (handraiseBtnClose) {
      handraiseBtnClose.click()
    }
    if (handraiseBtn) {
      handraiseBtn.classList.remove("active");
      handraiseBtn.classList.remove("hand-raise");
      handraiseBtn.disabled = false;
    }
  }
}
function appendElements(msg, userName, classList) {
  // console.log(msg);

  // Get the parent div element
  const parentDiv = document.getElementById('message_box');
  // $("#showchat").show();

  // Create a new div element
  const newDiv = document.createElement('div');
  const msgs = document.createElement('p');
  msgs.classList.add("msg")
  msgs.textContent = `${msg}`; // Add content to the new div
  const sender = document.createElement('span');
  sender.classList.add("userName");
  sender.textContent = `${userName}`; // Add content to the new div

  newDiv.classList.add('message-container'); // Add content to the new div
  newDiv.classList.add(classList); // Add content to the new div
  newDiv.appendChild(msgs)
  newDiv.appendChild(sender)

  // Create a new <br> element
  const br = document.createElement('br');

  // Append the new <br> and new div to the parent div
  // parentDiv.appendChild(br);
  parentDiv.appendChild(newDiv);
}

function canvas() {
  // var designer = new CanvasDesigner();

  // you can place widget.html anywhere
  // console.log(connection);

  designer.widgetHtmlURL = 'assets/rtcMultiConnection/widgets/widget.html';
  designer.widgetJsURL = 'widget.min.js';

  designer.addSyncListener(function (data) {
    var dataObj = {
      type: "whiteboard-data",
      data
    }
    // console.log(dataObj);
    connection.send(JSON.stringify(dataObj));
  });

  designer.setSelected('pencil');

  designer.setTools({
    pencil: true,
    text: true,
    image: true,
    pdf: true,
    eraser: true,
    line: true,
    arrow: true,
    dragSingle: true,
    dragMultiple: true,
    arc: true,
    rectangle: true,
    quadratic: true,
    bezier: true,
    marker: true,
    zoom: false,
    lineWidth: true,
    colorsPicker: true,
    extraOptions: true,
    code: true,
    undo: true
  });


  // Append the designer widget to the container
  // designer.appendTo(captureElement);

  // Append the designer widget to the container
  // designer.appendTo(captureElement);
  designer.appendTo(document.getElementById('widget-container'));
  // connection.onmessage = function (event) {
  //   if (event.data === 'plz-sync-points') {
  //     designer.sync();
  //     return;
  //   }

  //   designer.syncData(event.data);
  // };
  try {
    designer.captureStream(function (stream) {
      connection.addStream(stream);
      const userInfo = {
        role: localStorage.getItem('UserRole'),
        userName: info.FullName,
        whiteboardShare: true,
        UserId: info.UserId,
        info: localStorage.getItem('userInfo')
      };
      connection.extra = userInfo
      connection.getAllParticipants().forEach(function (pid) {
        connection.addTrack(connection.attachStreams[0], pid);
        startScreenSharing(connection.attachStreams[0])
        // connection.sendCustomMessage(pid, { type: 'update-user-info', extra: userInfo });
      });
    });
  } catch (ex) {
    console.log("Error occurred", ex);
  }
}

function initCanvasDesigner(Id) {
  var app = document.getElementById(Id);
  var selectedColor = "#00FF00"
  var selectedLineW = "5"
  app.style.backgroundColor = "#FFFFFF";

  // Create the canvas element
  var canvas = document.createElement('canvas');
  canvas.id = 'myCanvas';
  canvas.width = 400;
  canvas.height = 300;
  canvas.style.border = '1px solid grey';
  canvas.style.backgroundColor = 'white'; // Set canvas background color to white
  app.appendChild(canvas);

  // Create the controls container
  var controls = document.createElement('div');
  controls.className = 'controls';

  // Add Color Picker for Pencil, Line Width, Shape Selector, Undo Button, etc.
  var colorPicker = document.createElement('input');
  colorPicker.type = 'color';
  colorPicker.id = 'colorPicker';
  colorPicker.value = '#00FF00'; // Default color for pencil and shapes
  controls.appendChild(colorPicker);

  // Add Color Picker for Eraser
  var eraserColorPicker = document.createElement('input');
  eraserColorPicker.type = 'color';
  eraserColorPicker.id = 'eraserColorPicker';
  eraserColorPicker.value = '#ffffff'; // Default color for eraser (white)
  controls.appendChild(eraserColorPicker);

  var lineWidth = document.createElement('input');
  lineWidth.type = 'range';
  lineWidth.id = 'lineWidth';
  lineWidth.min = '1';
  lineWidth.max = '50';
  lineWidth.value = '5';
  controls.appendChild(lineWidth);

  var shapeSelect = document.createElement('select');
  shapeSelect.id = 'shapeSelect';
  var shapes = ['pencil', 'eraser', 'line', 'rectangle', 'circle', 'triangle', 'polygon', 'doc-text'];
  shapes.forEach(function (shape) {
    var option = document.createElement('option');
    option.value = shape;
    option.innerText = shape.charAt(0).toUpperCase() + shape.slice(1);
    shapeSelect.appendChild(option);
  });
  controls.appendChild(shapeSelect);

  var undoButton = document.createElement('button');
  undoButton.id = 'undoButton';
  undoButton.innerText = 'Undo';
  controls.appendChild(undoButton);

  var redoButton = document.createElement('button');
  redoButton.id = 'redoButton';
  redoButton.innerText = 'Redo';
  controls.appendChild(redoButton);

  // Add image upload input
  var imageUpload = document.createElement('input');
  imageUpload.type = 'file';
  imageUpload.id = 'imageUpload';
  imageUpload.accept = 'image/*';
  controls.appendChild(imageUpload);

  // Add document upload input
  var documentUpload = document.createElement('input');
  documentUpload.type = 'file';
  documentUpload.id = 'docUpload';
  documentUpload.accept = ".doc,.docx";
  controls.appendChild(documentUpload);

  app.appendChild(controls);

  const ctx = canvas.getContext("2d");

  let isDrawing = false;
  let currentShape = null; // Current shape being drawn
  let drawnShapes = []; // Stack of drawn shapes for undo/redo
  let undoneShapes = []; // Stack for redo operations

  // Set initial color and line width
  ctx.strokeStyle = colorPicker.value;
  ctx.lineWidth = lineWidth.value;

  // Listen for color picker change
  colorPicker.addEventListener('input', function () {
    ctx.strokeStyle = this.value;
  });

  // Listen for eraser color picker change
  eraserColorPicker.addEventListener('input', function () {
    // Update the color for eraser
    ctx.lineWidth = lineWidth.value; // Maintain the current line width
  });

  // Listen for line width change
  lineWidth.addEventListener('input', function () {
    ctx.lineWidth = this.value;
  });

  // Image upload functionality
  imageUpload.addEventListener('change', function (event) {
    const file = event.target.files[0];
    if (file) {
      const img = new Image();
      const reader = new FileReader();

      reader.onload = function (e) {
        img.src = e.target.result;
        img.onload = function () {
          ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear the canvas before drawing the image
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height); // Draw the uploaded image on the canvas
          drawnShapes.push({ type: 'image', image: img }); // Store the image in the shapes array
        };
      };

      reader.readAsDataURL(file);
    }
  });

  // Function to redraw all the shapes
  function redrawCanvas() {
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.fillStyle = 'white'; // Fill the canvas with white
    ctx.fillRect(0, 0, canvas.width, canvas.height); // Maintain white background
    drawnShapes.forEach(shape => {
      if (shape.type === 'image') {
        // Redraw the uploaded image
        ctx.drawImage(shape.image, 0, 0, canvas.width, canvas.height);
      } else if (shape.type === 'doc-text') {
        // Redraw the document text
        drawTextOnCanvas(shape.docText);
      } else {
        ctx.strokeStyle = shape.color;
        ctx.lineWidth = shape.lineWidth;
        ctx.beginPath();

        if (shape.type === 'pencil') {
          shape.points.forEach((point, index) => {
            if (index === 0) {
              ctx.moveTo(point.x, point.y);
            } else {
              ctx.lineTo(point.x, point.y);
            }
          });
        } else if (shape.type === 'line') {
          ctx.moveTo(shape.startX, shape.startY);
          ctx.lineTo(shape.endX, shape.endY);
        } else if (shape.type === 'rectangle') {
          ctx.rect(shape.startX, shape.startY, shape.endX - shape.startX, shape.endY - shape.startY);
        } else if (shape.type === 'circle') {
          const radius = Math.sqrt(Math.pow(shape.endX - shape.startX, 2) + Math.pow(shape.endY - shape.startY, 2));
          ctx.arc(shape.startX, shape.startY, radius, 0, Math.PI * 2);
        } else if (shape.type === 'triangle' || shape.type === 'polygon') {
          ctx.moveTo(shape.points[0].x, shape.points[0].y);
          shape.points.forEach(point => {
            ctx.lineTo(point.x, point.y);
          });
          ctx.closePath();
        }

        ctx.stroke();
      }
    });

    // If current shape is being drawn, update its path
    if (currentShape) {
      ctx.strokeStyle = currentShape.color;
      ctx.lineWidth = currentShape.lineWidth;
      ctx.beginPath();
      ctx.moveTo(currentShape.points[0].x, currentShape.points[0].y);
      currentShape.points.forEach(point => {
        ctx.lineTo(point.x, point.y);
      });
      ctx.stroke();
    }
  }

  // Mouse down event: Start drawing or erasing
  canvas.addEventListener('mousedown', function (e) {
    isDrawing = true;

    if (shapeSelect.value === 'pencil') {
      // Pencil-specific drawing
      ctx.strokeStyle = colorPicker.value; // Set pencil color
      currentShape = {
        type: 'pencil',
        color: ctx.strokeStyle,
        lineWidth: ctx.lineWidth,
        points: [{ x: e.offsetX, y: e.offsetY }]
      };
    } else if (shapeSelect.value === 'eraser') {
      // Eraser
      ctx.strokeStyle = eraserColorPicker.value; // Use eraser color
      currentShape = {
        type: 'pencil',
        color: eraserColorPicker.value,
        lineWidth: ctx.lineWidth,
        points: [{ x: e.offsetX, y: e.offsetY }]
      };
    } else if (shapeSelect.value === 'line' || shapeSelect.value === 'rectangle' || shapeSelect.value === 'circle') {
      // Start a new shape
      ctx.strokeStyle = colorPicker.value; // Set shape color to the first color picker
      currentShape = {
        type: shapeSelect.value,
        color: ctx.strokeStyle,
        lineWidth: ctx.lineWidth,
        startX: e.offsetX,
        startY: e.offsetY,
        endX: e.offsetX,
        endY: e.offsetY,
        points: [] // Initialize empty points for triangle and polygon
      };
    } else if (shapeSelect.value === 'triangle' || shapeSelect.value === 'polygon') {
      // Start a new shape
      ctx.strokeStyle = colorPicker.value; // Set shape color to the first color picker
      currentShape = {
        type: shapeSelect.value,
        color: ctx.strokeStyle,
        lineWidth: ctx.lineWidth,
        points: [{ x: e.offsetX, y: e.offsetY }]
      };
    }

    drawnShapes.push(currentShape);
    undoneShapes = []; // Clear redo stack after new drawing
  });

  // Mouse move event: Draw or erase as the mouse moves
  canvas.addEventListener('mousemove', function (e) {
    if (!isDrawing) return;

    const currentX = e.offsetX;
    const currentY = e.offsetY;

    if (shapeSelect.value === 'pencil' || shapeSelect.value === 'eraser') {
      // Update points for pencil/eraser
      currentShape.points.push({ x: currentX, y: currentY });
    } else if (shapeSelect.value === 'line' || shapeSelect.value === 'rectangle' || shapeSelect.value === 'circle') {
      // Update shape end points
      currentShape.endX = currentX;
      currentShape.endY = currentY;
    } else if (shapeSelect.value === 'triangle' || shapeSelect.value === 'polygon') {
      // Update polygon points
      currentShape.points.push({ x: currentX, y: currentY });
    }

    redrawCanvas(); // Redraw canvas with current updates
  });

  // Mouse up event: Stop drawing or erasing
  canvas.addEventListener('mouseup', function () {
    isDrawing = false;
    currentShape = null; // Reset the current shape
  });

  // Undo and Redo functionality
  undoButton.addEventListener('click', function () {
    if (drawnShapes.length > 0) {
      const undoneShape = drawnShapes.pop();
      undoneShapes.push(undoneShape); // Push the undone shape to redo stack
      redrawCanvas();
    }
  });

  redoButton.addEventListener('click', function () {
    if (undoneShapes.length > 0) {
      const redoShape = undoneShapes.pop();
      drawnShapes.push(redoShape); // Re-add the shape to drawn shapes stack
      redrawCanvas();
    }
  });

  // Document upload functionality
  documentUpload.addEventListener('change', function (event) {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        const arrayBuffer = reader.result;
        mammoth.extractRawText({ arrayBuffer: arrayBuffer })
          .then(function (result) {
            const docText = result.value; // Extracted text from the docx file
            currentShape = {
              type: "doc-text",
              color: ctx.strokeStyle,
              lineWidth: ctx.lineWidth,
              docText
            };
            drawnShapes.push(currentShape); // Add the document text as a shape to the stack
            undoneShapes = []; // Clear redo stack when a new document is uploaded
            redrawCanvas(); // Redraw the canvas with the document text
          })
          .catch(function (err) {
            console.error("Error extracting text from docx file:", err);
          });
      };
      reader.readAsArrayBuffer(file);
    }
  });

  // Function to draw the extracted text from document on the canvas
  function drawTextOnCanvas(text) {
    ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear the canvas before drawing
    ctx.fillStyle = 'black'; // Set text color
    ctx.font = "16px Arial"; // Set font for the text
    const lines = text.split("\n");
    let y = 20;
    lines.forEach(function (line) {
      ctx.fillText(line, 10, y);
      y += 20; // Move to the next line
    });
  }


  w_stream = canvas.captureStream(30); // 30 FPS
  castCanvas(w_stream);
}

function castCanvas(stream) {
  const info = JSON.parse(localStorage.getItem('userInfo'))
  connection.addStream(stream);

  whiteboardShare= stream.streamid
  connection.extra = {
    role: localStorage.getItem('UserRole'),
    userName: info.FullName,
    whiteboardShare: true,
    UserId: info.UserId,
    info: localStorage.getItem('userInfo')
  };
  // connection.extra = userInfo
  connection.getAllParticipants().forEach(function (pid) {
    connection.addTrack(connection.attachStreams[0], pid);
    // startScreenSharing(connection.attachStreams[0])
    // connection.sendCustomMessage(pid, { type: 'update-user-info', extra: userInfo });
  });
}

function stopSendingStream() {
  if (w_stream) {
      const msg = JSON.stringify({
        type: "close-whiteboard",
        streamid: w_stream.id, // Ensure you're using the correct property
      });
      // Remove the stream from the connection
      connection.removeStream(w_stream);
      // Stop all tracks in the stream
      w_stream.getTracks().forEach(track => {
          console.log("Stopping track:", track);
          track.stop();
      });
      // Optionally clear the w_stream reference
      w_stream = null;
      // Send the close message
      sendMessage(msg);
  } else {
      console.log("No active stream to stop.");
  }
}
function cleanup() {
  connection.getAllParticipants().forEach(pid => {
      connection.disconnectWith(pid);
  });
  
  connection.attachStreams.forEach(stream => {
      stream.getTracks().forEach(track => track.stop());
  });

  connection.closeSocket();
}

// Call cleanup on beforeunload
window.addEventListener("beforeunload", cleanup);